import React from "react"
import { Link } from "gatsby"

import { Button } from "components"
import NotFoundIcon from "images/404.svg"
import {
  NotFoundContainer,
  NotFoundImage,
  NotFoundInner,
  NotFoundSubTitle,
  NotFoundText,
  NotFoundTitle,
  NotFoundWrapper,
} from "./styled"

const NotFound = () => {
  return (
    <NotFoundWrapper>
      <NotFoundContainer>
        <NotFoundInner>
          <NotFoundTitle>
            <span>404</span>
          </NotFoundTitle>

          <NotFoundSubTitle>Page not found</NotFoundSubTitle>

          <NotFoundText>
            Oops, seems that there’s nothing in here. The page you’re looking
            for doesn’t exist.
          </NotFoundText>

          <Link to="/">
            <Button variant="dark">Go to homepage</Button>
          </Link>
        </NotFoundInner>

        <NotFoundImage src={NotFoundIcon} alt="404-image" />
      </NotFoundContainer>
    </NotFoundWrapper>
  )
}

export default NotFound
