import styled from "styled-components"

import { Container } from "components/Layout/styled"

export const NotFoundWrapper = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #17021b;
`

export const NotFoundContainer = styled(Container)`
  display: flex;
  gap: 50px;
  justify-content: space-between;

  @media (max-width: 768px) {
    flex-direction: column-reverse;
    align-items: center;
  }
`

export const NotFoundImage = styled.img`
  width: 350px;

  @media (max-width: 991px) {
    width: 250px;
  }

  @media (max-width: 768px) {
    width: 150px;
  }
`

export const NotFoundInner = styled.div`
  max-width: 400px;
  color: #fff;

  @media (max-width: 991px) {
    width: 300px;
  }

  @media (max-width: 768px) {
    text-align: center;
  }

  button {
    text-transform: none;
    min-width: 250px;
    width: 250px;

    @media (max-width: 991px) {
      font-size: 16px;
      padding: 12px 24px;
      min-width: 200px;
      width: 200px;
    }
  }
`

export const NotFoundTitle = styled.h1`
  font-weight: 700;
  font-size: 78px;
  margin: 0 0 22px 0;
  position: relative;
  color: #fff;
  letter-spacing: 4px;
  text-shadow: 0 0 11px #f000ff;
  display: inline-block;
  z-index: 1;

  @media (max-width: 991px) {
    font-size: 55px;
  }

  &::after {
    content: "404";
    position: absolute;
    top: 0;
    left: 0;
    color: #392152;
    filter: blur(19px);
    z-index: -1;
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #8c30ce;
    opacity: 0.1;
    filter: blur(40px);
    z-index: -2;
  }
  & span {
    padding: 0;
    animation: blink linear infinite 3s;
  }

  @keyframes blink {
    78% {
      color: inherit;
      text-shadow: inherit;
    }
    79% {
      color: #333;
    }
    80% {
      text-shadow: none;
    }
    81% {
      color: inherit;
      text-shadow: inherit;
    }
    82% {
      color: #333;
      text-shadow: none;
    }
    83% {
      color: inherit;
      text-shadow: inherit;
    }
    92% {
      color: #333;
      text-shadow: none;
    }
    92.5% {
      color: inherit;
      text-shadow: inherit;
    }
  }
`

export const NotFoundSubTitle = styled.h2`
  font-size: 28px;
  font-weight: 500;
  text-transform: uppercase;
  margin: 0 0 35px 0;

  @media (max-width: 991px) {
    font-size: 20px;
    margin: 0 0 20px 0;
  }
`

export const NotFoundText = styled.p`
  font-size: 21px;
  margin: 0 0 50px 0;

  @media (max-width: 991px) {
    font-size: 16px;
    margin: 0 0 35px 0;
  }
`
