import React from "react"

import NotFound from "../containers/NotFound"

const NotFoundPage = () => (
  <>
    <NotFound />
  </>
)

export default NotFoundPage
